import React from 'react'

import Page from '../components/layout/Page'
import Layout from '../components/layout/Layout'
import useStoryblok from '../utils/storyblok'
import Meta from '../components/common/Meta'
import { StoreContextProvider } from '../hooks/contexts';

export default function PageIndex({ pageContext }) {
  const story = useStoryblok(pageContext.story)

  return (
    <StoreContextProvider>
      <Layout showLoader={false} paddingTop={false} hideLoader={true}>
        <Meta meta={story.content.meta} />
        <Page blok={story.content} />
      </Layout>
    </StoreContextProvider>
  )
}
